
import { Vue, Component, Prop, Emit, ModelSync, Ref } from 'vue-property-decorator';
import autosize from 'autosize';

@Component({
  name: 'Textarea',
})
export default class Textarea extends Vue {
  @Ref('textarea') readonly textarea!: HTMLTextAreaElement;

  @ModelSync('value', 'change', { default: '' }) readonly innerValue!: string;

  @Prop({ default: null }) label: string;
  @Prop({ default: 'text' }) type: string;
  @Prop({ default: 'left' }) textAlign: string;
  @Prop({ default: '' }) placeholder: string;
  @Prop({ default: '' }) rows: string;
  @Prop({ default: false }) error: boolean;
  @Prop({ default: false }) compact: boolean;

  @Emit('blur')
  onBlur(e: Event) {
    return e;
  }

  @Emit('focus')
  onFocus(e: Event) {
    return e;
  }

  mounted() {
    autosize(this.textarea);
  }

  beforeDestroy() {
    autosize.destroy(this.textarea);
  }
}
